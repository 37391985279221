import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ProfileLayout from 'src/components/layouts/ProfileLayout'
import Header from 'src/components/sections/Header'
import PortfolioGrid from 'src/components/sections/PortfolioGrid'
import { signOut } from 'src/utils/auth'
import { USER_LIST } from 'src/utils/data/header'
import { useAuth } from 'src/context/useAuth'
import { ICellRendererParams } from 'ag-grid-community'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_PORTFOLIO_INFO_BY_USER_ID, GET_USER_PERMISSIONS } from 'src/client/queries/auth'
import { ThreeBlocksLoader } from 'src/components/ui/loader/Loader'
import { calculateSum, formatGridData } from 'src/utils/profile'
import { currencyFormatter as cur, toFixedTrunc } from 'src/utils/relisting'
import FooterLandingPage from 'src/components/sections/FooterLandingPage'
import { GET_REFERRAL_INFO } from 'src/client/queries/profile'
import Skeleton from 'react-loading-skeleton'

export default function Portfolio() {
  const [gridData, setGridData] = useState([])
  const [pointsData, setPointsData] = useState<any>([])
  const wide = false

  const numberFormatter = (params: ICellRendererParams) => {
    if (!params.value) return 'NA'
    return params.value.toLocaleString()
  }

  const currencyFormatter = (params: ICellRendererParams) => {
    if (isNaN(params.value)) return 'NA'
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    return formatter.format(params.value)
  }

  const [getPortfolioInfoByUserId, { loading }] = useLazyQuery(GET_PORTFOLIO_INFO_BY_USER_ID, {
    variables: {
      userId: localStorage?.getItem('currentUser') || localStorage?.getItem('userId'),
    },
    onCompleted: (data: any) => {
      setGridData(formatGridData(data.getPortfolioInfoByUserId.portfolio_info))
      setPointsData(data.getPortfolioInfoByUserId.personal_points)
    },
  })

  const addPercentageSign = (params: ICellRendererParams) => {
    if (!params.value) return 'NA'
    return toFixedTrunc(params.value, 2) + '%'
  }

  useEffect(() => {
    getPortfolioInfoByUserId()
  }, [getPortfolioInfoByUserId, gridData])

  //@ts-ignore
  const { user } = useAuth()

  const columnDefs = [
    {
      field: 'Real Estate Address',
      cellRenderer: 'LinkCellRenderer',
      cellClass: ['main_title', 'common_class'],
      tooltipField: 'View Property',
      // minWidth: 300,
    },
    {
      field: 'No of Tokens',
      headerName: 'No. of Tokens',
      filter: 'agNumberColumnFilter',
      cellRenderer: numberFormatter,
    },
    { field: 'Total', filter: 'agNumberColumnFilter', cellRenderer: currencyFormatter },

    {
      field: 'Est ROI',
      headerName: 'Est. ROI',
      filter: 'agNumberColumnFilter',
      cellRenderer: addPercentageSign,
    },
    { field: 'Price', filter: 'agNumberColumnFilter', cellRenderer: currencyFormatter },
    // { field: 'Share of RE', filter: 'agNumberColumnFilter', cellRenderer: addPercentageSign },
    { field: 'Initial RE Value', filter: 'agNumberColumnFilter', cellRenderer: currencyFormatter },
  ]
  // if (loading) {
  //   return (
  //     <>
  //       <Header user={user} variant="standard" />
  //       <ProfileLayout>
  //         <div className="menu">
  //           <ul className="menu__list">
  //             {USER_LIST.map((nav: any) => (
  //               <li key={nav.label} onClick={nav.handler} className="menu__list__item">
  //                 <Link key={nav.label} to={`${nav.href}`}>
  //                   {nav.label}
  //                 </Link>
  //               </li>
  //             ))}
  //             <li key="signout" className="menu__list__item" onClick={signOut}>
  //               <Link to="/">Signout</Link>
  //             </li>
  //           </ul>
  //         </div>

  //         <div className="personal">
  //           <ThreeBlocksLoader />
  //         </div>
  //       </ProfileLayout>
  //       <FooterLandingPage />
  //     </>
  //   )
  // }
  return (
    <>
      <Header user={user} variant="standard" />
      <ProfileLayout>
        <div className="menu">
          <ul className="menu__list">
            {USER_LIST.map((nav: any) => (
              <li key={nav.label} onClick={nav.handler} className="menu__list__item">
                <Link key={nav.label} to={`${nav.href}`}>
                  {nav.label}
                </Link>
              </li>
            ))}
            <li key="signout" className="menu__list__item" onClick={signOut}>
              <Link to="/">Signout</Link>
            </li>
          </ul>
        </div>
        <div className="personal">
          <div className="status -portfolio">
            <div className="inv_value">
              <div className="inv">My Total Investment Value</div>
              <div className="fig"> {cur(calculateSum(gridData, 'Total'))}</div>
            </div>
            <div className="inv_value -personalpoints">
              <div className="inv">My personal points:</div>
              <div className="personaldetails">
                <div className="row">
                  <div className="header">KYC completed and wallet whitelisted:</div>
                  {loading ? (
                    <div className="value">
                      <Skeleton />
                    </div>
                  ) : (
                    <div className="value">{pointsData.kyc_wallet_whitelisted_points} points</div>
                  )}
                </div>
                <div className="row">
                  <div className="header">Total number of tokens invested:</div>
                  {loading ? (
                    <div className="value">
                      <Skeleton />
                    </div>
                  ) : (
                    <div className="value">{pointsData.total_tokens_invested}</div>
                  )}{' '}
                </div>
                <div className="row">
                  <div className="header">Investment points (# of tokens * 500):</div>

                  {loading ? (
                    <div className="value">
                      <Skeleton />
                    </div>
                  ) : (
                    <div className="value">{pointsData.investment_points}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <PortfolioGrid columnDefs={columnDefs} rowData={gridData} wide={wide} />
        </div>
      </ProfileLayout>
      <FooterLandingPage />
    </>
  )
}
